import React from "react"
import Layout from "../../components/Layout/Layout"
import SEO from "../../components/Seo/Seo"
import styled from 'styled-components'
import PageHeader from '../../components/Layout/PageHeader/PageHeader'
import SectionPageMargin from '../../components/Layout/Section/SectionPageMargin'
import Heading2 from '../../components/Type/Heading2'
import Paragraph1 from '../../components/Type/Paragraph1'
import ReadyToGetStarted from '../../components/ReadyToGetStarted/ReadyToGetStarted'

const ValuationPage = ({location}) =>
  (
    <Layout>

      <SEO title="How much is my car worth? Solving the impossible challenge!"
        description="We wanted to answer the question “How much is a Mercedes Benz C300 worth” and used a range of publicly available tools to see what they suggested"
        location={location}
      />

      <PageHeader
        title="How much is my car worth? Solving the impossible challenge!"
        text="When thinking about selling a prestige car, one of the first questions people ask is “What is the value of my car?"
      />

      <SectionPageMargin>

        <ContentStyle>
          <Heading2>What is the value of my car?</Heading2>

          <Paragraph1>
            When thinking about selling a prestige car, one of the first questions people ask is “What is the value of my car?”
          </Paragraph1>

          <Paragraph1>
            Would you like the good news, or the bad news first?
          </Paragraph1>

          <Paragraph1>
            The <strong>good news</strong> is that there are many, many tools, calculators and estimators that will provide you with a value for your car.
          </Paragraph1>

          <Paragraph1>
            The <strong>bad news</strong> is that they will each provide you with a different result!
          </Paragraph1>

          <Paragraph1>
            Unfortunately, for a typical seller, pricing a car correctly is practically impossible. Yet, the price set for a car is the #1 determining factor of selling quickly, slowly or not at all.
          </Paragraph1>

          <Paragraph1>
            We recently did a benchmarking test, using a popular prestige car (2017 Mercedes Benz C300) with typical mileage as our example. We wanted to answer the question “How much is a Mercedes Benz C300 worth” and used a range of publicly available tools to see what they suggested.
          </Paragraph1>

          <Paragraph1>
            Some of the tools will provide you with alternative prices for selling privately vs a dealer trade-in, with a dealer-trade in price typically 15% - 20% less than the private seller price. For this study, we focused on the private seller valuations that were provided.
          </Paragraph1>

          <Paragraph1>
            We used a real 2017 Mercedes Benz C300 advertised for sale (on Carsales) as our benchmark, and then reduced the price by 5% to allow for some negotiation. Here are the valuation results from six different sources;
          </Paragraph1>

          <table>
            <thead>
              <tr>
                <th>Valuation tool / estimator</th><th>Valuation provided</th><th>Difference to benchmark</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Benchmark car listed for sale on Carsales</td><td><strong>$56,050</strong> (advertised sale price less 5%)</td><td>na</td>
              </tr>
              <tr>
                <td>Carsales Value my Car estimator</td><td>$47,700 avg of range, $49,700 top of range</td><td>-11.5% to -15%</td>
              </tr>
              <tr>
                <td>Manheim Auctions Car Price Guide</td><td>$50,768</td><td>-9.5%</td>
              </tr>
              <tr>
                <td>Glass's mycarprice.com.au</td><td>$50,900</td><td>-9%</td>
              </tr>
              <tr>
                <td>Autotrader free valuation for your vehicle</td><td>$56,840 (avg of range)</td><td>+1.5%</td>
              </tr>
              <tr>
                <td>Carsguide Price Guide</td><td>$59,380</td><td>+6%</td>
              </tr>
              <tr>
                <td>Carsales - of 8 private seller matching cars</td><td>Range from $55,500 - $68,000</td><td>-1% to +20% (!)</td>
              </tr>
              <tr>
                <td>Carsales - of 6 dealer sale matching cars</td><td>Range from to $52,868 - $55,888</td><td>-1% to -5.5%</td>
              </tr>
            </tbody>
          </table>

          <Paragraph1>
            If we were a genuine seller of a used prestige car, choosing any one of these methods could suggest a valuation for the car of anywhere from $47,700 to $68,000, a whopping 30% difference!
          </Paragraph1>

          <Paragraph1>
            We are not suggesting that any of these valuation tools is more accurate than any other (we would need to benchmark a lot more cars to reach that conclusion), the point we want to make is that every car value estimation tool will give you very different results - calling into question the accuracy of any of them.
          </Paragraph1>

          <Heading2>So how <em>can</em> I find the value of my car?</Heading2>


          <Paragraph1>
            Your car is worth exactly what the market says it is worth! Of course, the only way to know that is to advertise your car for sale, and see whether you get enquiries or not.
          </Paragraph1>

          <Paragraph1>
            In a normal market (as I right this, in early May 2020, we are not in a normal market - lead volumes on Carsales are down 25% due to Coronavirus lockdowns and the economic impact).
          </Paragraph1>

          <Paragraph1>
            If you are trying to sell your car privately and work out the value of your car before advertising, we would recommend simply comparing your car to other cars for sale, here is a process you could use;
          </Paragraph1>

          <ol>
            <li>On Carsales.com.au (which has the best interface for this) you should search for used cars that match your year, make, model, badge &amp; series as closely as possible </li>
            <li>Then filter by Kilometers and enter a range +/- 10,000km to your car’s mileage</li>
            <li>Hopefully you will have about 10 results, if not see if you can add more filters to make the results match your car as closely as possible </li>
            <li>Next, look at any outliers e.g. cars that have unusual colours or specs, and ignore these </li>
            <li>Finally, create a “bracket” of six cars that are the closest to the car you wish to sell. Mileage is more important than options. For the point of a valuation, generally location doesn’t make much difference</li>
            <li>Write down the prices of these cars, hopefully they will be reasonably consistent (ignoring any outliers) and you can use this as a guide for your own car’s listing price</li>
            <li>Save each of these six cars to your Carsales account. You will now get notifications when a car has been sold or had a price reduction.</li>
          </ol>

          <Paragraph1>
            You should put more confidence in the pricing of Dealer listings than Private Seller listings. Dealers have access to better data than private sellers, and have a higher incentive to sell a car quickly. A private sale listing might have been advertised unsold for two months, but you wouldn’t know.
          </Paragraph1>

          <Paragraph1>
            The length of time a car has remained listed for sale is a great way to know if the price is right (or not). Car dealerships can pay to access this information using Carsale’s LiveMarket software, which shows the ‘Age’ in days of similar cars listed for sale as well as cars that have been sold and are no longer listed.
          </Paragraph1>

          <Paragraph1>
            As a private seller you can have access to a simplified version of LiveMarket, which is called Price Assist - which is available only <strong>after</strong> you have posted your listing ad. This also includes the ‘days online’ for similar cars - giving you a clue as to which are good benchmarks and which are probably overpriced and not selling. We suggest that after you post your car listing, you second-check your price using the Price Assist tool.
          </Paragraph1>
          <Paragraph1>
            If you want to sell your car quickly, you should obviously try to be more competitive in price than other cars available for sale. For more tips on how to sell your car privately, please <a title="Guide and checklist" href="https://www.summon.com.au/articles/2020-guide-checklist">download our guide and checklist</a>.
          </Paragraph1>
          <Paragraph1>
            If you are looking to sell a prestige car, and would like to use our concierge service to help you, <a title="contact us" href="https://www.summon.com.au/contact">contact us</a> and we will happily provide you with a free valuation of your car.
          </Paragraph1>


        </ContentStyle>
      </SectionPageMargin>

      <ReadyToGetStarted />

    </Layout>

  )

export default ValuationPage


const ContentStyle = styled.div`
  table {
    margin-top: 30px;
    margin-bottom: 50px;
    width: 100%;
    background-color: #f8f8f9;
  }

  tr {
    background-color: white;
    color: black;
  }

  th {
    padding: 10px;
    background-color: #3479ae;
    color: white;
  }

  td {
    padding: 5px;
    padding-left: 15px;
    background-color: #f8f8f9;
  }

  li {
    line-height: 2rem;
  }

`;
